import React from 'react'
import GettingStarted from './GettingStarted'
import {
    Header,
    HeaderSizes,
    CodeBlock,
    Card,
    ThemeVariants,
    TableItem,
    Graph,
} from 'rds'
import Css from './CSS'
import Theming from './Theming'

const Main = () => {
    return (
        <React.Fragment>
            <GettingStarted />
            <TableItem />
            <div id='components' className='rds-m_top'>
                <Header
                    title='Components'
                    text='RDS is a collection of React components that lets you build your application very quickly. Choose one of the components at the Sidebar to read the docs.'
                />
                <div className='rds-m_top__md'>
                    <Header
                        title='1. Import components and constants'
                        size={HeaderSizes.SMALL}
                    />
                    <CodeBlock className='rds-m_top__sm'>
                        {`import { Card, Header, HeaderSizes } from 'rds'`}
                    </CodeBlock>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='Constants are values provided for customizing the components'
                        headerText='Behind the scenes, these are just String values that you can directly type, but we provide them in order to prevent errors.'
                        variant={ThemeVariants.WARNING}
                        headerSize={HeaderSizes.XSMALL}
                    />
                </div>
                <div className='rds-m_top__md'>
                    <Header
                        title='2. Start building'
                        text='You are ready to go! Create some cool components'
                        size={HeaderSizes.SMALL}
                    />
                    <CodeBlock className='rds-m_top__sm'>
                        {`<Tag variant={ThemeVariants.WARNING}>\n     My custom tag\n</Tag>`}
                    </CodeBlock>
                </div>
            </div>
            <TableItem />
            <Theming />
            <TableItem />
            <Css />
            <section id='troubleshoot' className='rds-m_top rds-full-block'>
                <Header
                    title='Troubleshoot'
                    text='Every component has an integrated troubleshooting state that provides information about the props that are wrong or missing'
                />
                <div className='rds-m_top__md'>
                    <Header
                        title="What you'll see"
                        text='This message will list all the required fields for the component you are trying to use'
                        size={HeaderSizes.SMALL}
                    />
                    <Graph />
                </div>
                <div className='rds-m_top__md'>
                    <Header
                        title='Your code'
                        text='As you can see the Graph component is missing some required props'
                        size={HeaderSizes.SMALL}
                    />
                    <CodeBlock className='rds-m_top__sm'>
                        {`// Lacking required props...
<Graph
    itemFieldName='value'
    itemDescriptionFieldName='description'
/>`}
                    </CodeBlock>
                    <Card
                        className='rds-m_top__sm'
                        headerTitle='In case you do not get this information'
                        headerText='You always have the posibility of checking the docs at Components.'
                        variant={ThemeVariants.WARNING}
                        headerSize={HeaderSizes.XSMALL}
                    />
                </div>
            </section>
            <section id='donate' className='rds-m_top rds-full-block'>
                <Header
                    title='Help us'
                    text='RDS is a small project that tries to make developers and designers life easier providing plug-n-play React components'
                />
                <form
                    className='rds-m_top__md'
                    action='https://www.paypal.com/cgi-bin/webscr'
                    method='post'
                    target='_top'
                    style={{ float: 'left', maxWidth: '200px' }}
                >
                    <input type='hidden' name='cmd' value='_s-xclick' />
                    <input
                        type='hidden'
                        name='hosted_button_id'
                        value='QA839UURWHEDN'
                    />
                    <input
                        type='image'
                        src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
                        border='0'
                        name='submit'
                        title='PayPal - The safer, easier way to pay online!'
                        alt='Donate with PayPal button'
                    />
                    <img
                        alt=''
                        border='0'
                        src='https://www.paypal.com/en_UY/i/scr/pixel.gif'
                        width='1'
                        height='1'
                    />
                </form>
            </section>
        </React.Fragment>
    )
}

export default Main
